<div class="d-flex">
    <app-sidenav></app-sidenav>
    <div class="container-fluid total-page">
        <div class="row page-header">      
            <div class="col-md-12 col-12 col-lg-12 col-sm-12 mt-2 align-center">
                <div class="cont1 centercont">
                    <button class="button-center bgg-clr col-6" [class.cont]="bodyDisplay === 'IP'"
                      (click)="bodyDisplay = 'IP';clearSearchFields();getAllLabServices();getAllWardsList();loadSampleCollectionList();">In-patient</button>
                    <button class="button-center bgg-clr col-6" [class.cont]="bodyDisplay === 'OP'"
                      (click)="bodyDisplay = 'OP';clearSearchFields();getAllLabServices();loadSampleCollectionList();">Out-patient</button>
                </div>
            </div>
            <!-- <div class="col-1 col-md-1 col-lg-1 col-sm-1">
                <div class="ic-postion float-right mt-2">
                    <span class="fa-stack fa-1x has-badge" data-count="5">
                        <i class="fa fa-circle fa-stack-1x fa-inverse"></i>
                        <i class="fa fa-bell fa-stack-1x ic red-cart"></i>
                    </span>
                    <span class="dash-main-icon">To-Do</span>
                </div>
            </div> -->
        </div>
        <!-- Advance search -->
        <div class="row justify-content-center m-0">
            <div class="col-md-10 mt-2">
                <form [formGroup]="advSearchForm" (ngSubmit)="onSearch()" class="form-inline" autocomplete="off">
                    <div class="row advance-search">
                        <div class="col-md-12 mt-2 d-flex">
                            <div class="col-md-4  mb-3 p-0">
                                <div class="form-group">
                                    <label class="col-md-4 p-0 modname" >UMR NO:</label>
                                    <div class="col-md-6">
                                    <input  name="textinput" formControlName="URMNO" class="form-control input-textt1 " type="text">
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-md-4  mb-3 p-0" [ngClass]="{ 'opDisplay': bodyDisplay !== 'OP' }">
                                <div class="form-group">
                                    <label class="col-md-4 modname" >VISIT ID:</label>
                                    <div class="col-md-6">
                                    <input  name="textinput" formControlName="VISITID" class="form-control input-textt1 " type="text">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  mb-3 p-0" [ngClass]="{ 'opDisplay': bodyDisplay !== 'IP' }">
                                <div class="form-group">
                                    <label class="col-md-4 modname" >IP NO:</label>
                                    <div class="col-md-6">
                                    <input  name="textinput" formControlName="IPNUM" class="form-control input-textt1 " type="text">
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-md-4 mb-2  p-0">
                                <div class="form-group">
                                    <label class="col-md-4 modname align-box " >Name:</label>
                                    <div class="col-md-6">
                                    <input  name="textinput" formControlName="PATIENTNAME" class="form-control input-textt1 " type="text">
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div class="col-md-12 mt-2 d-flex">
                            <div class="col-md-4  mb-3 p-0">
                                <div class="form-group">
                                    <label class="col-md-4 p-0 modname" >Request Date:</label>
                                    <div class="col-md-6">
                                        <input  name="searchDate" formControlName="LABDATE" class="form-control input-textt1 " type="date">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4  ml-3 p-0">
                                <div class="form-group">
                                    <label class="col-md-4 p-0 modname" >Service Name:</label>
                                    <div class="col-md-8">
                                        <select 
                                        class="form-control input-textt2 w-100"
                                        name="searchServiceName" 
                                        formControlName="SERVICENAME"
                                        id="searchServiceName" 
                                        >
                                        <option value="">Select Service Name</option>
                                        <option *ngFor="let serviceName of allLabServices" 
                                        [ngValue]="serviceName?.SERVICE_NAME">{{serviceName?.SERVICE_NAME}}</option>
                                        </select> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-0" *ngIf="bodyDisplay == 'IP'">
                                <div class="form-group">
                                    <label class="col-md-4 p-0 modname" >Ward Name:</label>
                                    <div class="col-md-6">
                                        <select 
                                        class="form-control input-textt2 w-100"
                                        name="searchwardName"
                                        formControlName="WARDNAME" 
                                        id="searchwardName" 
                                        required>
                                        <option value="">Select Ward Name</option>
                                        <option *ngFor="let wards of wardListArr" 
                                        [ngValue]="wards?.WM_WARD_M_ID">{{wards?.WM_WARD_NAME}}</option>
                                        </select> 
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div class="col-md-12 mt-2" style="text-align: right;">
                            <button type="submit" class="btn btn-primary advanced-search-btn mr-4">Search</button>
                            <button type="button" (click)="clearSearchFields();" class="btn btn-primary advanced-search-btn">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
                
            <div class="col-md-12 d-flex pt-4 pl-0 pb-4 justify-content-center">
                <div class="col-md-2 text-left">
                    <button class="button-center bgg-clr" [class.searchCont]="searchType === 'All'" (click)="searchType = 'All';loadSampleCollectionList();">All</button>
                    <!-- <br /> -->
                    <!-- <span class="sampleCountLeft labCount">({{totalCount}})</span> -->
                </div>
                <div class="col-md-2 text-center">
                    <button class="button-center bgg-clr" [class.searchCont]="searchType === 'Pending'" (click)="searchType = 'Pending';loadSampleCollectionList();">Pending </button>
                    <!-- <br /> -->
                    <!-- <span class="labCount">({{pendingCount}})</span> -->
                </div>
                <div class="col-md-2 text-right">
                    <button class="button-center bgg-clr" [class.searchCont]="searchType === 'Completed'"  (click)="searchType = 'Completed';loadSampleCollectionList();">Completed </button>
                    <!-- <br /> -->
                    <!-- <span class="sampleCountRight labCount">({{completedCount}})</span> -->
                </div>
                <div class="col-md-2 text-right">
                    <button class="button-center bgg-clr" [class.searchCont]="searchType === 'ResultEntry'"  (click)="searchType = 'ResultEntry';loadSampleCollectionList();">Result Entry </button>
                    <!-- <br /> -->
                    <!-- <span class="sampleCountRight labCount">({{resultEntryCount}})</span> -->
                </div>
                <div class="col-md-2 text-right">
                    <!-- <button class="button-center bgg-clr" (click)="searchType = 'ResultEntry';loadSampleCollectionList();">Result Entry </button> -->
                    <div class="dropdown text-right">
                        <button type="button" class="button-center dropdown-toggle bgg-clr" [class.searchCont]="searchType === 'Options'" 
                        data-toggle="dropdown" (click)="searchType = 'Options';">Options</button>
                        <div id="myDropdown" class="dropdown-menu">
                          <button class="btn btn-sm dropdown-item" [hidden]="bodyDisplay === 'OP'" (click)="IpBillingPopup(ipBillingPopup);">IP Billing Info</button>
                          <button class="btn btn-sm dropdown-item" [hidden]="bodyDisplay === 'IP'" (click)="OpBillingPopup(opBillingPopup);">OP Billing Info</button>                          
                        </div>
                    </div>
                    <!-- <br /> -->
                    <!-- <span class="sampleCountRight labCount">({{resultEntryCount}})</span> [class.searchCont]="searchType === 'ResultEntry'"  -->
                </div>
            </div>
        </div>
        
        <div class="row justify-content-center mb-2" [ngClass]="{ 'opDisplay': bodyDisplay == 'OP' }">
            <div class="col-md-12" >
                <div class="matcontent">
                    <mat-table #table [dataSource]="ipSampleCollection" matSort >
        
                        <ng-container matColumnDef="UMRNUM">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div>UMR NO</div>
                        </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element">
                            <div>{{element.patientID}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="IPNUM">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                            <div>IP NUM</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"  matTooltip="">
                            <div>{{element.ipNum}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="NAME">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                            <div>NAME</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"  matTooltip="">
                            <div>{{element.title}} {{element.patientName}}</div>
                        </mat-cell>
                        </ng-container>
                
                        <ng-container matColumnDef="GENDERAGE">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>GENDER/<br/> AGE</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" >
                            <div>{{element.gender}}/{{element.age}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="REFERREDBY">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>REFERRED BY</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div>{{element.referredBy}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="REQUESTDATE">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>REQUEST DATE</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div>{{element.requestDate}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="REQUESTTIME">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>REQUEST TIME</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"  matTooltip="">
                            <div>{{element.requestTime}} </div>
                        </mat-cell>
                        </ng-container>            
                        
                        <ng-container matColumnDef="ACTION">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>ACTION</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" >
                            <div *ngIf="!element.resultEntry">
                                <button (click)="getPatientLabServices(element,'IP')"><span>{{element.sampleCollection}}</span></button>
                            </div>
                            <div *ngIf="element.resultEntry">
                                <button (click)="getLabResultSampleIds(element,'IP')"><span>Enter Results</span></button>
                            </div>
                        </mat-cell>
                        </ng-container>
                
                        <mat-header-row *matHeaderRowDef="ipcolumnsToDisplay; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let element; columns: ipcolumnsToDisplay"></mat-row>
                    </mat-table>
            
                    <div class="mat-norecords" *ngIf="ipSampleCollection.data.length === 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
                    <mat-paginator #ipPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
                    </mat-paginator>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mb-2" [ngClass]="{ 'opDisplay': bodyDisplay == 'IP' }">
            <div class="col-md-12" >
                <div class="matcontent">
                    <mat-table #table [dataSource]="opSampleCollection" matSort >
        
                        <ng-container matColumnDef="UMRNUM">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div>UMR NO</div>
                        </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element">
                            <div>{{element.patientID}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="VISITID">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                            <div>VISIT ID</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"  matTooltip="">
                            <div>{{element.visitID}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="NAME">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>      
                            <div>NAME</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"  matTooltip="">
                            <div>{{element.title}} {{element.patientName}}</div>
                        </mat-cell>
                        </ng-container>
                
                        <ng-container matColumnDef="GENDERAGE">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>GENDER/<br/> AGE</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" >
                            <div>{{element.gender}}/{{element.age}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="REFERREDBY">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>REFERRED BY</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div>Dr.{{element.referredBy}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="REQUESTDATE">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>REQUEST DATE</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div>{{element.requestDate}}</div>
                        </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="REQUESTTIME">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>REQUEST TIME</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"  matTooltip="">
                            <div>{{element.requestTime}} </div>
                        </mat-cell>
                        </ng-container>            
                        
                        <ng-container matColumnDef="ACTION">
                        <mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <div>ACTION</div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" >
                            <!-- <div *ngIf="element.resultEntry">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action"><mat-icon>more_vert</mat-icon></button>
                                <mat-menu #menu="matMenu">
                                    <button (click)="getPatientLabServices(element,'OP')" mat-menu-item><span>{{element.sampleCollection}}</span></button>
                                    <button *ngIf="element.resultEntry" (click)="getResultEntryServices(element,'OP')" mat-menu-item ><span>Result Entry</span></button>
                                </mat-menu>
                            </div> -->
                            <div *ngIf="!element.resultEntry">
                                <button (click)="getPatientLabServices(element,'OP')"><span>{{element.sampleCollection}}</span></button>
                            </div>
                            <!-- <div *ngIf="element.resultEntry">
                                <button (click)="getResultEntryServices(element,'OP')"><span>Enter Results</span></button>
                            </div> -->
                            <div *ngIf="element.resultEntry">
                                <button (click)="getLabResultSampleIds(element,'OP')"><span>Enter Results</span></button>
                            </div>
                        </mat-cell>
                        </ng-container>
                
                        <mat-header-row *matHeaderRowDef="opcolumnsToDisplay; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let element; columns: opcolumnsToDisplay"></mat-row>
                    </mat-table>
            
                    <div class="mat-norecords" *ngIf="opSampleCollection.data.length === 0"> NO RECORD FOUND WITH THE SEARCH VALUES</div>
                    <mat-paginator #opPaginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons style="border-bottom-left-radius:6px; border-bottom-right-radius:6px" class="mat-paginator-sticky">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>

<ng-template #OPPreAuthPopup let-modal>
    <div class="modal-content">

        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h4 class="mode-title text-center mt-2 ">Report Information</h4>
            </div>
            <div class="col-md-4">
                <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal"
                    aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
            </div>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-12">

                    <div class="form-inline" autocomplete="off">
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Name :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo.title}} {{patientInfo.patientName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">UMR Number :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.patientID}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Referred by :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.referredBy}}
                                        <!-- <small>02-13456</small> -->
                                    </p>

                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Gender/Age :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">
                                        {{patientInfo.gender}} / {{patientInfo.age}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Visit ID :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.visitID}}</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Department :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.department}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Request Date :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.requestDate}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Request Time :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.requestTime}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <hr>
        <div class="col-md-12 tab d-flex p-0" *ngIf="servicesList !== undefined">
            <div class="col-md-2 p-0 m-0" *ngFor="let service of servicesList;let i = index">
                <button  class="tablinks" [ngClass]="{'selService':serviceIndex == i}" (click)="serviceIndex = i;sampleServices = servicesList[i]" > <span *ngIf="service[0].sequenceNo !== undefined">Sample Id: {{service[0].sequenceNo}}</span></button>
            </div>
        </div>
        <hr>
        <div>
        <!-- <div *ngFor="let service of servicesList;let i = index"> -->
            <div class="mode-box" >
                <div class="row" >
                    <div class="col-md-1 "></div>
                    <div class="col-md-4 modname text-white mt-1"> Billing ID : {{sampleServices[0].billingId}} </div>
                    <div class="col-md-2 "></div>
                    <div class="col-md-2  modname text-white mt-1"> {{sampleServices[0].serviceTypeName}}</div>
                    <div class="col-md-1 "></div>
                    <div class="col-md-2  modname  mt-1"> <img class="ic-hover" src="assets/labimages/icon.svg">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 ml-3">
                    <label class="modname">
                        <u> Test Name</u>
                    </label>
                </div>
                <div class="col-md-2"> 
                    <label class="modname">
                        <u>Accept</u>
                    </label>
               </div>
                <div class="col-md-3">
                    <label class="modname">
                        <u>Sample Id: {{sampleServices[0].sequenceNo}}</u>
                    </label>
                </div>
                <div class="col-md-3">
                    <label class="modname">
                        <u>Requested By: {{sampleServices[0].serviceReuestedBy}}</u>
                    </label>
                </div>
                <!-- <div class="col-md-2">
                    <label class="modname">
                        <u>Tube Color : </u><label class="text-danger "> &nbsp;Null</label>
                    </label>
                </div>
                <div class="col-md-2">
                    <label class="modname">
                        <u>Barcode:</u>
                    </label>
                </div> -->
            </div>
            <div class="row mb-2" *ngFor="let list of sampleServices;let k = index;">
                <div class="col-md-2 ml-3 dis-align">
                    <label class="modname">
                       {{k+1}}.{{list.serviceName}}
                    </label>
                </div>
                <div class="col-md-1 dis-align">
                    <input type="checkbox" name="acceptServie" aria-label="Checkbox for following text input" [disabled]="(list.serviceStatus)?'':null" class="max-1" [(ngModel)]="list.serviceRequest" #acceptServie = "ngModel" (change)="checkServiceIsActive(sampleServices)" value='true'>
                </div>
                <div class="col-md-2 dis-align">
                    <label class="modname">
                        <u>Remarks:</u>
                    </label>
                    <input type="text" class="max" [disabled]="(list.serviceStatus)?'':null" [(ngModel)]="list.serviceRemarks" #remarks = "ngModel" name="remarks"> 
                </div>
                <div class="col-md-3 dis-align m-3 d-flex">
                    <div class="col-md-11"></div>
                    <!-- <i class="fa fa-info-circle col-md-1" aria-hidden="true"></i> -->
                </div>
            </div>
            <div class="col-md-11 service-btn">
                <button type="button" class="cont12 float-right" (click)="saveServiceRequests(sampleServices)" [disabled]="!servicesSequence[sampleServices[0].sequenceNo]">Save</button>
            </div>
        </div>
       
        <hr>
       
        <div class="row mb-4">
            <div class="col-md-11">
                <!-- <button class="cont12 float-right m-2" data-dismiss="modal" aria-hidden="true"> Submit</button>
                <button class="cont12 float-right m-2"> Print</button> -->
            </div>
        </div>
    </div>
</ng-template>

<!-- IP Report Infomation -->
<ng-template #IPPreAuthPopup let-modal>
    <div class="modal-content">
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h4 class="mode-title text-center mt-2 ">Report Information</h4>
            </div>
            <div class="col-md-4">
                <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal"
                    aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
            </div>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-12">

                    <div class="form-inline" autocomplete="off">
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Name :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">IP Number :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.IP_NR}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Ward(Bed No) :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Gender/Age :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.GENDER}}/{{patientInfo?.AGE}}</p>
                                </div>
                            </div>
                        </div>
                        
                        <br />

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Admitting Doctor :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.DM_NAME}}
                                        <!-- <small>02-13456</small> -->
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Payee Name :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.PIM_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Referred By :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.REFERRED_BY}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Request Time :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo.requestTime}}</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>
        <!-- <div class="col-md-12 text-right">
            <span id="pendingDot">.</span><span>Pending Service</span>
            <span id="completeDot">.</span><span>Completed Service</span>
        </div> -->
        <hr>
        <div class="col-md-12 tab d-flex p-0" *ngIf="servicesList !== undefined">
            <div class="col-md-2 m-0" *ngFor="let service of servicesList;let i = index">
                <button  class="tablinks" [ngClass]="{'selService':serviceIndex == i }" (click)="serviceIndex = i;sampleServices = servicesList[i]" > <span *ngIf="service[0].sequenceNo !== undefined">Sample Id: {{service[0].sequenceNo}}</span></button>
            </div>
        </div>
        <hr>
        <div>
            <div class="mode-box" >
                <div class="row" >
                    <!-- <div class="col-md-1 "></div> -->
                    <!-- <div class="col-md-4 modname text-white mt-1"> Billing ID : {{service[0].billingId}} </div> -->
                    <!-- <div class="col-md-2 "></div> -->
                    <div class="col-md-10  modname text-white mt-1 text-center"> {{sampleServices[0].serviceTypeName}}</div>
                    <!-- <div class="col-md-1 "></div> -->
                    <div class="col-md-2  modname  mt-1"> <img class="ic-hover" src="assets/labimages/icon.svg">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 ml-3">
                    <label class="modname">
                        <u> Test Name</u>
                    </label>
                </div>
                <div class="col-md-1"> 
                    <label class="modname">
                        <u>Accept</u>
                    </label>
               </div>
               <div class="col-md-1"> 
                    <label class="modname">
                        <u>Reject</u>
                    </label>
                </div>
                <div class="col-md-3">
                    <!-- <label class="modname">
                        <u>Sample Id: {{sampleServices[0].sequenceNo}}</u>
                    </label> -->
                </div>
                <div class="col-md-3">
                    <label class="modname">
                        <u>Requested By: {{sampleServices[0].serviceReuestedBy}}</u>
                    </label>
                </div>
                <!-- <div class="col-md-2">
                    <label class="modname">
                        <u>Tube Color : </u><label class="text-danger "> &nbsp;Null</label>
                    </label>
                </div>
                <div class="col-md-2">
                    <label class="modname">
                        <u>Barcode:</u>
                    </label>
                </div> -->
            </div>
            <div class="row mb-2" *ngFor="let list of sampleServices;let k = index;">
                <div class="col-md-2 ml-3 dis-align">
                    <label class="modname">
                       {{k+1}}.{{list.serviceName}}
                    </label>
                </div>
                <div class="col-md-1 dis-align">
                    <input type="checkbox" name="acceptServie" aria-label="Checkbox for following text input"  class="max-1" [disabled]="(list.serviceStatus)?'':null" [(ngModel)]="list.serviceAccept" #acceptServie = "ngModel" (change)="list.serviceReject=false;checkServiceIsActive(sampleServices)" value='true'>
                </div>
                <div class="col-md-1 dis-align">
                    <input type="checkbox" name="acceptServie" aria-label="Checkbox for following text input" [disabled]="(list.serviceStatus)?'':null" class="max-1" [(ngModel)]="list.serviceReject" #acceptServie = "ngModel" (change)="list.serviceAccept = false;checkServiceIsActive(sampleServices);" value='true'>
                </div>
                <div class="col-md-2 dis-align servRemarks">
                    <label class="modname">
                        <u>Remarks:</u>
                    </label>
                    <input type="text" class="max" [disabled]="(list.serviceStatus)?'':null" [(ngModel)]="list.serviceRemarks" #remarks = "ngModel" name="remarks" (ngModelChange)="checkServiceIsActive(sampleServices);">
                    <div *ngIf="list.serviceReject && list.serviceRemarks == ''"  class="bg-warningtxt width100">
                        Remarks is required
                      </div> 
                </div>
            </div>
            <div class="col-md-11 service-btn">
                <button type="button" class="cont12 float-right" (click)="saveServiceRequests(sampleServices)" [disabled]="!servicesSequence[sampleServices[0].sequenceNo]">Save</button>
            </div>
        </div>
       
        <hr>
       
        <div class="row mb-4">
            <div class="col-md-11">
                <!-- <button class="cont12 float-right m-2" data-dismiss="modal" aria-hidden="true"> Submit</button>
                <button class="cont12 float-right m-2"> Print</button> -->
            </div>
        </div>
    </div>
</ng-template>

<!-- ResultEntry -->
<ng-template #IPResultEntryPreAuthPopup let-modal>
    <div class="modal-content">
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h4 class="mode-title text-center mt-2 ">REPORT INFORMATION</h4>
            </div>
            <div class="col-md-4">
                <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal" aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
            </div>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-inline" autocomplete="off">
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Name :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.TITLE}} {{patientInfo?.PATIENT_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">IP Number :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.IP_NR}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Ward(Bed No) :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.WM_WARD_NAME}} <span>({{patientInfo?.WM_BED_NO}})</span></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Gender/Age :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo?.GENDER}}/{{patientInfo?.AGE}}</p>
                                </div>
                            </div>
                        </div>
                        
                        <br />

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Admitting Doctor :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.DM_NAME}}
                                        <!-- <small>02-13456</small> -->
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Payee Name :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.PIM_NAME}}</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname"> Referred By :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo?.REFERRED_BY}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="col-md-12 tab  p-0 d-flex" *ngIf="labSampleIds !== undefined">
                <div class="col-md-2 p-0 m-0"  *ngFor="let sequenceNo of labSampleIds;let i = index">
                    <button class="tablinks " [ngClass]="{'selService':resultIndex == i}" (click)="resultIndex = i;getSampleIdResults(sequenceNo);" > <span *ngIf="sequenceNo !== undefined">Sample Id: {{sequenceNo}}</span></button>
                </div>
            </div>
            <hr>
            <div class="texteditor" *ngIf="labSampleTextEditor">
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                    <ngx-editor [style]="{'height':'320px'}"
                                [editor]="editor"
                                [placeholder]="'Type here...'"
                                [ngModel]="html"
                                [formControl]="form.controls['editorContent']"
                    ></ngx-editor>
                </div>
                <div class="row m-4">
                    <div class="col-md-8">
                        <div class="form-group row no-gutters">
                            <label class="col-4 modname reportHeading pt-1 pl-2"><h6><b>Reporting Doctors</b></h6></label>
                            <div class="col-5 px-0">
                                <select 
                                class="form-control input-textt2 w-100"
                                name="reportingDoctor" 
                                id="reportingDoctor"
                                [(ngModel)]="selectedreportingDoctorID"
                                #reportingDoctor = "ngModel" 
                                required>
                                <option value="0">Select Doctor Name</option>
                                <option *ngFor="let doctor of reportingDoctors" 
                                [ngValue]="doctor?.reportingDoctorId">{{doctor?.reportingDoctor}}</option>
                                </select> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-right">
                        <button class="cont13 col-md-3 ml-2"  (click)="addLabResultsData(labResultServices)">Save</button>
                        <button class="cont13 col-md-3 ml-2" (click)="printTexteditorData()" [disabled]="(html != '' && html != null)?'':null">print</button>
                    </div>
                </div> 
            </div>
            <div class="normalTextfield" *ngIf="!labSampleTextEditor">
                <div class="mode-box" >
                    <div class="row m-0" >
                        <div class="col-md-2 modname text-white mt-1 text-center">PARAMETER</div>
                        <div class="col-md-2 modname text-white mt-1 text-center">RESULT</div>
                        <div class="col-md-1 modname text-white mt-1 text-center">UNITS</div>
                        <div class="col-md-2 modname text-white mt-1 text-center">REFERENCE RANGES</div>
                        <div class="col-md-3 modname text-white mt-1 text-center">REMARKS</div>
                        <div class="col-md-2 modname text-white mt-1 text-center">METHODOLOGY</div>
                    </div>
                </div>

                <ng-container *ngIf="labServiceResult.length != 0 && labServiceType != 'subservices'">
                    <div class="row" *ngFor="let service of labServiceResult; let i= index">

                        <ng-container *ngIf="service['SUB_TESTS'].length != 0 && service['SUB_PACKAGE_TESTS']">
                            <div class="col-md-12 resultLabel" *ngIf="service['SUB_TESTS'].length != 0"><h5>1{{service['NAME']}}</h5></div>

                            <ng-container *ngIf="service['SUB_TESTS'].length != 0">
                                <div class="row pl-4" *ngFor="let subTest of service['SUB_TESTS'];let j=index;">
                                    <div class="col-md-10 pl-3 resultLabel"><h6>{{subTest['NAME']}}</h6></div><br />
                                    <ng-container  *ngIf="subTest['SUB_TEST_SERVICES'].length != 0">
                                    
                                        <div class="col-md-12 pl-3" *ngFor="let subTestService of subTest['SUB_TEST_SERVICES']">
                                            <div class="row pl-2">
                                                <div class="col-md-2">{{subTestService.TEST_PROFILE_NAME}}</div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subTestService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subTestService)" value="subTestService.RESULT_NUMERIC_VALUE" [disabled]="subTestService.RESULT_STATUS">

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="subTestService.RESULT_ALERT !='' " *ngIf="subTestService.RESULT_STATUS">{{subTestService.RESULT_ALERT}}</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MIN_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE && subTestService.MIN_VALUE > subTestService.RESULT_NUMERIC_VALUE )" *ngIf="!subTestService.RESULT_STATUS">L</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MAX_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE && subTestService.RESULT_NUMERIC_VALUE > subTestService.MAX_VALUE)" *ngIf="!subTestService.RESULT_STATUS">H</span>
                                                </div>
                                            
                                                <div class="col-md-1 text-center mt-1 padTop">
                                                    <input type="text" name="serviceUnits" [(ngModel)]="subTestService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subTestService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subTestService)" >
                                                </div>
                                                <div class="col-md-2 text-center mt-1">
                                                    <textarea name="serviceRefRange" [(ngModel)]="subTestService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)" >{{subTestService.RESULT_NORMAL_RANGES}}</textarea>
                                                </div>
                                                <div class="col-md-3 text-center mt-1">
                                                    <textarea name="serviceResultRemarks" [(ngModel)]="subTestService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)">{{subTestService.RESULT_REMARKS}}</textarea>
                                                </div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="methodology" [(ngModel)]="subTestService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subTestService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subTestService)">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="subTest['SUB_TEST_INNER_SERVICES'].length != 0">
                                        <div class="col-md-12 pl-4" *ngFor="let innerHeading of subTest['SUB_TEST_INNER_SERVICES'] | keyvalue">
                                            <div class="row col-md-12 pl-3 resultLabel"><h6>{{subTest.SUB_TEST_INNER_SERVICES[innerHeading.key][0].SUBTEST_INNER_HEADING}}</h6></div><br />
                                            <ng-container>
                                                <div class="col-md-12" *ngFor="let innerService of subTest['SUB_TEST_INNER_SERVICES'][innerHeading.key];let k = index;">
                                                    <div class="row">
                                                        <div class="col-md-2">{{innerService.TEST_PROFILE_NAME}}</div>
                                                        <div class="col-md-2 text-center mt-1 padTop">
                                                            <input type="text" name="serviceResult" class="mode-input1"  [(ngModel)]="innerService.RESULT_NUMERIC_VALUE"  #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(innerService)" value="innerService.RESULT_NUMERIC_VALUE" [disabled]="innerService.RESULT_STATUS">

                                                            <span class="resultEnterStatus" [class.checkresultStatus]="innerService.RESULT_ALERT !='' " *ngIf="innerService.RESULT_STATUS">{{innerService.RESULT_ALERT}}</span>

                                                            <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MIN_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE && innerService.MIN_VALUE > innerService.RESULT_NUMERIC_VALUE )" *ngIf="!innerService.RESULT_STATUS">L</span>

                                                            <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MAX_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE && innerService.RESULT_NUMERIC_VALUE > innerService.MAX_VALUE )" *ngIf="!innerService.RESULT_STATUS">H</span>
                                                        </div>
                                                    
                                                        <div class="col-md-1 text-center mt-1 padTop">
                                                            <input type="text" name="serviceUnits" [(ngModel)]="innerService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="innerService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(innerService)" >
                                                        </div>
                                                        <div class="col-md-2 text-center mt-1">
                                                            <textarea name="serviceRefRange" [(ngModel)]="innerService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)" >{{innerService.RESULT_NORMAL_RANGES}}</textarea>
                                                        </div>
                                                        <div class="col-md-3 text-center mt-1">
                                                            <textarea name="serviceResultRemarks" [(ngModel)]="innerService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)">{{innerService.RESULT_REMARKS}}</textarea>
                                                        </div>
                                                        <div class="col-md-2 text-center mt-1 padTop">
                                                            <input type="text" name="methodology" [(ngModel)]="innerService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="innerService.RESULT_METHOD" (ngModelChange)="checkResultServiceIsActive(innerService)">
                                                        </div>
                                                
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    
                                </div>
                            </ng-container>

                        </ng-container>
                        <!-- serive itself -->
                        <ng-container *ngIf="service['SUB_TESTS'].length == 0 && service['SUB_TEST_SERVICES'].length == 0  && service['SUB_TEST_INNER_SERVICES'].length == 0  && !service['SUB_PACKAGE_TESTS']">
                            <div class="row col-md-12 pl-5">
                                <div class="col-md-2 resultLabel">{{service.NAME}}</div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="service.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(service)" value="service.RESULT_NUMERIC_VALUE" [disabled]="service.RESULT_STATUS">

                                    <span class="resultEnterStatus" [class.checkresultStatus]="service.RESULT_ALERT !='' " *ngIf="service.RESULT_STATUS">{{service.RESULT_ALERT}}</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(service.MIN_VALUE != NULL && service.RESULT_NUMERIC_VALUE != '' && service.RESULT_NUMERIC_VALUE && service.MIN_VALUE > service.RESULT_NUMERIC_VALUE )" *ngIf="!service.RESULT_STATUS">L</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(service.MAX_VALUE != NULL && service.RESULT_NUMERIC_VALUE != '' && service.RESULT_NUMERIC_VALUE && service.RESULT_NUMERIC_VALUE > service.MAX_VALUE )" *ngIf="!service.RESULT_STATUS">H</span>
                                </div>
                            
                                <div class="col-md-1 text-center mt-1 padTop">
                                    <input type="text" name="serviceUnits" [(ngModel)]="service.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="service.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(service)" >
                                </div>
                                <div class="col-md-2 text-center mt-1">
                                    <textarea name="serviceRefRange" [(ngModel)]="service.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{service.RESULT_NORMAL_RANGES}}</textarea>
                                </div>
                                <div class="col-md-3 text-center mt-1">
                                    <textarea name="serviceResultRemarks" [(ngModel)]="service.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)">{{service.RESULT_REMARKS}}</textarea>
                                </div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="methodology" [(ngModel)]="service.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="service.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(service)">
                                </div>
                        
                            </div>
                                
                        </ng-container>
                        <!-- sub sub service -->
                        <ng-container *ngIf="service['SUB_TESTS'].length == 0 && !service['SUB_PACKAGE_TESTS']  && service['SUB_TEST_INNER_SERVICES'].length != 0">
                            <div class="col-md-12 resultLabel"><h5>{{service['NAME']}}</h5></div>
                            <ng-container *ngIf="service['SUB_TESTS'].length == 0  && service['SUB_TEST_SERVICES'].length != 0">
                                
                                <ng-container>
                                    <div class="col-md-12" *ngFor="let testService of service['SUB_TEST_SERVICES'];let p = index;">
                                        <div class="row">
                                            <div class="col-md-2">{{testService.TEST_PROFILE_NAME}}</div>
                                            <div class="col-md-2 text-center mt-1 padTop">
                                                <input type="text" name="serviceResult" class="mode-input1"  [(ngModel)]="testService.RESULT_NUMERIC_VALUE"  #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(testService)" value="testService.RESULT_NUMERIC_VALUE" [disabled]="testService.RESULT_STATUS">
                                                
                                                <span class="resultEnterStatus" [class.checkresultStatus]="testService.RESULT_ALERT !='' " *ngIf="testService.RESULT_STATUS">{{testService.RESULT_ALERT}}</span>

                                                <span class="resultEnterStatus" [class.checkresultStatus]="(testService.MIN_VALUE != NULL && testService.RESULT_NUMERIC_VALUE != '' && testService.RESULT_NUMERIC_VALUE && testService.MIN_VALUE > testService.RESULT_NUMERIC_VALUE )" *ngIf="!testService.RESULT_STATUS">L</span>

                                                <span class="resultEnterStatus" [class.checkresultStatus]="(testService.MAX_VALUE != NULL && testService.RESULT_NUMERIC_VALUE != '' && testService.RESULT_NUMERIC_VALUE && testService.RESULT_NUMERIC_VALUE > testService.MAX_VALUE )" *ngIf="!testService.RESULT_STATUS">H</span>
                                            </div>
                                        
                                            <div class="col-md-1 text-center mt-1 padTop">
                                                <input type="text" name="serviceUnits" [(ngModel)]="testService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="testService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(testService)" >
                                            </div>
                                            <div class="col-md-2 text-center mt-1">
                                                <textarea name="serviceRefRange" [(ngModel)]="testService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2" (ngModelChange)="checkResultServiceIsActive(testService)" >{{testService.RESULT_NORMAL_RANGES}}</textarea>
                                            </div>
                                            <div class="col-md-3 text-center mt-1">
                                                <textarea name="serviceResultRemarks" [(ngModel)]="testService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2" (ngModelChange)="checkResultServiceIsActive(testService)">{{testService.RESULT_REMARKS}}</textarea>
                                            </div>
                                            <div class="col-md-2 text-center mt-1 padTop">
                                                <input type="text" name="methodology" [(ngModel)]="testService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="testService.RESULT_METHOD" (ngModelChange)="checkResultServiceIsActive(testService)">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="service['SUB_TESTS'].length == 0  && service['SUB_TEST_INNER_SERVICES'].length != 0">
                                <div class="col-md-12 pl-4" *ngFor="let innerHeading of service['SUB_TEST_INNER_SERVICES'] | keyvalue">
                                    <div class="row col-md-12 pl-3 resultLabel"><h6>{{service.SUB_TEST_INNER_SERVICES[innerHeading.key][0].SUBTEST_INNER_HEADING}}</h6></div><br />
                                    <ng-container>
                                        <div class="col-md-12" *ngFor="let innerService of service['SUB_TEST_INNER_SERVICES'][innerHeading.key];let k = index;">
                                            <div class="row">
                                                <div class="col-md-2">{{innerService.TEST_PROFILE_NAME}}</div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="serviceResult" class="mode-input1"  [(ngModel)]="innerService.RESULT_NUMERIC_VALUE"  #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(innerService)" value="innerService.RESULT_NUMERIC_VALUE" [disabled]="innerService.RESULT_STATUS">
                                                    
                                                    <span class="resultEnterStatus" [class.checkresultStatus]="innerService.RESULT_ALERT !='' " *ngIf="innerService.RESULT_STATUS">{{innerService.RESULT_ALERT}}</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MIN_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE && innerService.MIN_VALUE > innerService.RESULT_NUMERIC_VALUE )" *ngIf="!innerService.RESULT_STATUS">L</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MAX_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE > innerService.MAX_VALUE )" *ngIf="!innerService.RESULT_STATUS">H</span>
                                                </div>
                                            
                                                <div class="col-md-1 text-center mt-1 padTop">
                                                    <input type="text" name="serviceUnits" [(ngModel)]="innerService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="innerService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(innerService)" >
                                                </div>
                                                <div class="col-md-2 text-center mt-1">
                                                    <textarea name="serviceRefRange" [(ngModel)]="innerService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)" >{{innerService.RESULT_NORMAL_RANGES}}</textarea>
                                                </div>
                                                <div class="col-md-3 text-center mt-1">
                                                    <textarea name="serviceResultRemarks" [(ngModel)]="innerService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)">{{innerService.RESULT_REMARKS}}</textarea>
                                                </div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="methodology" [(ngModel)]="innerService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="innerService.RESULT_METHOD" (ngModelChange)="checkResultServiceIsActive(innerService)">
                                                </div>
                                        
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                            

                        </ng-container>
                        <ng-container  *ngIf="service['SUB_TESTS'].length == 0 && service['SUB_TEST_SERVICES'].length != 0 && service['SUB_TEST_INNER_SERVICES'].length == 0">
                            <div class="col-md-12 resultLabel"><h5>{{service['NAME']}}</h5></div>
                            <div class="col-md-12 pl-3" *ngFor="let subTestService of service['SUB_TEST_SERVICES']">
                                <div class="row pl-2">
                                    <div class="col-md-2">{{subTestService.TEST_PROFILE_NAME}}</div>
                                    <div class="col-md-2 text-center mt-1 padTop">
                                        <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subTestService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subTestService)" [disabled]="subTestService.RESULT_STATUS" >
                                        
                                        <span class="resultEnterStatus" [class.checkresultStatus]="subTestService.RESULT_ALERT !='' " *ngIf="subTestService.RESULT_STATUS">{{subTestService.RESULT_ALERT}}</span>

                                        <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MIN_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE  && subTestService.MIN_VALUE > subTestService.RESULT_NUMERIC_VALUE )" *ngIf="!subTestService.RESULT_STATUS">L</span>

                                        <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MAX_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE && subTestService.RESULT_NUMERIC_VALUE > subTestService.MAX_VALUE )" *ngIf="!subTestService.RESULT_STATUS">H</span>
                                    </div>
                                
                                    <div class="col-md-1 text-center mt-1 padTop">
                                        <input type="text" name="serviceUnits" [(ngModel)]="subTestService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subTestService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subTestService)" >
                                    </div>
                                    <div class="col-md-2 text-center mt-1">
                                        <textarea name="serviceRefRange" [(ngModel)]="subTestService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)" >{{subTestService.RESULT_NORMAL_RANGES}}</textarea>
                                    </div>
                                    <div class="col-md-3 text-center mt-1">
                                        <textarea name="serviceResultRemarks" [(ngModel)]="subTestService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)">{{subTestService.RESULT_REMARKS}}</textarea>
                                    </div>
                                    <div class="col-md-2 text-center mt-1 padTop">
                                        <input type="text" name="methodology" [(ngModel)]="subTestService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subTestService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subTestService)">
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </ng-container>

                <!-- Renal Profile Sub Tests -->

                <ng-container *ngIf="labServiceResult.length != 0 && labServiceType == 'subservices'">
                    <!-- Individual subTest Start -->
                    <div class="col-md-12 resultLabel" *ngIf="labServiceResult.single.length != 0"><h5>{{labServiceResult.single[0].SERVICE_NAME}}</h5></div>
                    <div class="row" *ngFor="let sigleService of labServiceResult.single; let i= index">
                        <div class="row col-md-12 pl-5">
                            <div class="col-md-2 resultLabel1">{{sigleService.TEST_PROFILE_NAME}}</div>
                            <div class="col-md-2 text-center mt-1 padTop">
                                <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="sigleService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(sigleService)" value="sigleService.RESULT_NUMERIC_VALUE" [disabled]="sigleService.RESULT_STATUS">

                                <span class="resultEnterStatus" [class.checkresultStatus]="sigleService.RESULT_ALERT !='' " *ngIf="sigleService.RESULT_STATUS">{{sigleService.RESULT_ALERT}}</span>

                                <span class="resultEnterStatus" [class.checkresultStatus]="(sigleService.MIN_VALUE != NULL && sigleService.RESULT_NUMERIC_VALUE != '' && sigleService.RESULT_NUMERIC_VALUE && sigleService.MIN_VALUE > sigleService.RESULT_NUMERIC_VALUE )" *ngIf="!sigleService.RESULT_STATUS">L</span>

                                <span class="resultEnterStatus" [class.checkresultStatus]="(sigleService.MAX_VALUE != NULL && sigleService.RESULT_NUMERIC_VALUE != '' && sigleService.RESULT_NUMERIC_VALUE > sigleService.MAX_VALUE )" *ngIf="!sigleService.RESULT_STATUS">H</span>
                            </div>
                        
                            <div class="col-md-1 text-center mt-1 padTop">
                                <input type="text" name="serviceUnits" [(ngModel)]="sigleService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="sigleService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(sigleService)" >
                            </div>
                            <div class="col-md-2 text-center mt-1">
                                <textarea name="serviceRefRange" [(ngModel)]="sigleService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{sigleService.RESULT_NORMAL_RANGES}}</textarea>
                            </div>
                            <div class="col-md-3 text-center mt-1">
                                <textarea name="serviceResultRemarks" [(ngModel)]="sigleService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(sigleService)">{{sigleService.RESULT_REMARKS}}</textarea>
                            </div>
                            <div class="col-md-2 text-center mt-1 padTop">
                                <input type="text" name="methodology" [(ngModel)]="sigleService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="sigleService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(sigleService)">
                            </div>
                    
                        </div>
                    </div>
                    <!-- Individual subTest End -->
                    <div class="col-md-12 p-0 m-0" *ngFor="let sigleSubService of labServiceResult.subService | keyvalue; let l= index">
                        <div class="col-md-12 resultLabel pl-4" *ngIf="sigleSubService.key != ''"><h5>{{sigleSubService.key}}</h5></div>
                        <div class="row" *ngFor="let subService of labServiceResult.subService[sigleSubService.key]['single']; let k= index">
                            <div class="row col-md-12 pl-5">
                                <div class="col-md-2 resultLabel1 pl-4">{{subService.TEST_PROFILE_NAME}}</div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subService)" value="subService.RESULT_NUMERIC_VALUE" [disabled]="subService.RESULT_STATUS">
                                    
                                    <span class="resultEnterStatus" [class.checkresultStatus]="subService.RESULT_ALERT !='' " *ngIf="subService.RESULT_STATUS">{{subService.RESULT_ALERT}}</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subService.MIN_VALUE != NULL && subService.RESULT_NUMERIC_VALUE != '' && subService.RESULT_NUMERIC_VALUE && subService.MIN_VALUE > subService.RESULT_NUMERIC_VALUE )" *ngIf="!subService.RESULT_STATUS">L</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subService.MAX_VALUE != NULL && subService.RESULT_NUMERIC_VALUE != '' && subService.RESULT_NUMERIC_VALUE > subService.MAX_VALUE )" *ngIf="!subService.RESULT_STATUS">H</span>
                                </div>
                            
                                <div class="col-md-1 text-center mt-1 padTop">
                                    <input type="text" name="serviceUnits" [(ngModel)]="subService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subService)" >
                                </div>
                                <div class="col-md-2 text-center mt-1">
                                    <textarea name="serviceRefRange" [(ngModel)]="subService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{subService.RESULT_NORMAL_RANGES}}</textarea>
                                </div>
                                <div class="col-md-3 text-center mt-1">
                                    <textarea name="serviceResultRemarks" [(ngModel)]="subService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subService)">{{subService.RESULT_REMARKS}}</textarea>
                                </div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="methodology" [(ngModel)]="subService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subService)">
                                </div>
                            
                            </div>
                        </div>
                        <div *ngIf="labServiceResult.subService[sigleSubService.key]['innerHead'] != undefined">
                            <div class="col-md-12" *ngFor="let subInnerService of labServiceResult.subService[sigleSubService.key]['innerHead'] | keyvalue; let t= index">
                                <div class="col-md-12 resultLabel pl-4">{{subInnerService.key}}</div>
                                <div class="col-md-12" *ngFor="let subInnerHeadService of labServiceResult.subService[sigleSubService.key]['innerHead'][subInnerService.key]; let t= index">
                                    <div class="row col-md-12 pl-3">
                                        <div class="col-md-2 resultLabel1 pl-3">{{subInnerHeadService.TEST_PROFILE_NAME}}</div>
                                        <div class="col-md-2 text-center mt-1 padTop">
                                            <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subInnerHeadService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)" value="subInnerHeadService.RESULT_NUMERIC_VALUE" [disabled]="subInnerHeadService.RESULT_STATUS">
                                            
                                            <span class="resultEnterStatus" [class.checkresultStatus]="subInnerHeadService.RESULT_ALERT !='' " *ngIf="subInnerHeadService.RESULT_STATUS">{{subInnerHeadService.RESULT_ALERT}}</span>

                                            <span class="resultEnterStatus" [class.checkresultStatus]="(subInnerHeadService.MIN_VALUE != NULL && subInnerHeadService.RESULT_NUMERIC_VALUE != '' && subInnerHeadService.RESULT_NUMERIC_VALUE  && subInnerHeadService.MIN_VALUE > subInnerHeadService.RESULT_NUMERIC_VALUE )" *ngIf="!subInnerHeadService.RESULT_STATUS">L</span>

                                            <span class="resultEnterStatus" [class.checkresultStatus]="(subInnerHeadService.MAX_VALUE != NULL && subInnerHeadService.RESULT_NUMERIC_VALUE != '' && subInnerHeadService.RESULT_NUMERIC_VALUE && subInnerHeadService.RESULT_NUMERIC_VALUE > subInnerHeadService.MAX_VALUE )" *ngIf="!subInnerHeadService.RESULT_STATUS">H</span>
                                        </div>
                                    
                                        <div class="col-md-1 text-center mt-1 padTop">
                                            <input type="text" name="serviceUnits" [(ngModel)]="subInnerHeadService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subInnerHeadService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)" >
                                        </div>
                                        <div class="col-md-2 text-center mt-1">
                                            <textarea name="serviceRefRange" [(ngModel)]="subInnerHeadService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{subInnerHeadService.RESULT_NORMAL_RANGES}}</textarea>
                                        </div>
                                        <div class="col-md-3 text-center mt-1">
                                            <textarea name="serviceResultRemarks" [(ngModel)]="subInnerHeadService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)">{{subInnerHeadService.RESULT_REMARKS}}</textarea>
                                        </div>
                                        <div class="col-md-2 text-center mt-1 padTop">
                                            <input type="text" name="methodology" [(ngModel)]="subInnerHeadService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subInnerHeadService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
                
                <div class="row m-4">
                    <div class="col-md-8">
                        <div class="form-group row no-gutters">
                            <label class="col-4 modname reportHeading pt-1 pl-2"><h6><b>Reporting Doctors</b></h6></label>
                            <div class="col-5 px-0">
                                <select 
                                class="form-control input-textt2 w-100"
                                name="reportingDoctor" 
                                id="reportingDoctor"
                                [(ngModel)]="selectedreportingDoctorID"
                                #reportingDoctor = "ngModel" 
                                required>
                                <option value="0">Select Doctor Name</option>
                                <option *ngFor="let doctor of reportingDoctors" 
                                [ngValue]="doctor?.reportingDoctorId">{{doctor?.reportingDoctor}}</option>
                                </select> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-right">
                        <button class="cont13 col-md-3 ml-2" (click)="addLabResultsData(labServiceResult)" [disabled]="labResPrintBtn && labReportingDoctors[selectedreportingDoctorID] == selectedDoctor">Save</button>
                        <button class="cont13 col-md-3 ml-2" [disabled]="!labResPrintBtn || (labReportingDoctors[selectedreportingDoctorID] != selectedDoctor)" (click)="printLabResultsFinal()">print</button>
                    </div>
                </div> 
            </div>
        </div> 
    </div>
</ng-template>

<ng-template #OPResultEntryPreAuthPopup let-modal>
    <div class="modal-content">
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <h4 class="mode-title text-center mt-2 ">REPORT INFORMATION</h4>
            </div>
            <div class="col-md-4">
                <i class="fa fa-times text-danger float-right handCursor p-3" data-dismiss="modal"
                    aria-hidden="true" (click) ="onClosePopup()">&nbsp;<span class="text-dark" >close</span></i>
            </div>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-12">

                    <div class="form-inline" autocomplete="off">
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Name :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">{{patientInfo.title}} {{patientInfo.patientName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">UMR Number :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.patientID}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Referred by :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.referredBy}}
                                        <!-- <small>02-13456</small> -->
                                    </p>

                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Gender/Age :</label>
                                <div class="col-sm-7">
                                    <p class="modname2">
                                        {{patientInfo.gender}} / {{patientInfo.age}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Visit ID :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.visitID}}</p>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Department :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.department}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-5 modname">Request Date :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.requestDate}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 pb-3">
                            <div class="form-group">
                                <label class="col-sm-6 modname">Request Time :</label>
                                <div class="col-sm-6">
                                    <p class="modname2">{{patientInfo.requestTime}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="col-md-12 tab  p-0 d-flex" *ngIf="labSampleIds !== undefined">
                <div class="col-md-2 p-0 m-0"  *ngFor="let sequenceNo of labSampleIds;let i = index">
                    <button class="tablinks " [ngClass]="{'selService':resultIndex == i}" (click)="resultIndex = i;getSampleIdResults(sequenceNo);" > <span *ngIf="sequenceNo !== undefined">Sample Id: {{sequenceNo}}</span></button>
                </div>
            </div>
            <hr>
            <div class="texteditor" *ngIf="labSampleTextEditor">
                <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                    <ngx-editor [style]="{'height':'320px'}"
                                [editor]="editor"
                                [placeholder]="'Type here...'"
                                [ngModel]="html"
                                [formControl]="form.controls['editorContent']"
                    ></ngx-editor>
                </div>
                <div class="row m-4">
                    <div class="col-md-8">
                        <div class="form-group row no-gutters">
                            <label class="col-4 modname reportHeading pt-1 pl-2"><h6><b>Reporting Doctors</b></h6></label>
                            <div class="col-5 px-0">
                                <select 
                                class="form-control input-textt2 w-100"
                                name="reportingDoctor" 
                                id="reportingDoctor"
                                [(ngModel)]="selectedreportingDoctorID"
                                #reportingDoctor = "ngModel" 
                                required>
                                <option value="0">Select Doctor Name</option>
                                <option *ngFor="let doctor of reportingDoctors" 
                                [ngValue]="doctor?.reportingDoctorId">{{doctor?.reportingDoctor}}</option>
                                </select> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-right">
                        <button class="cont13 col-md-3 ml-2"  (click)="addLabResultsData(labResultServices)">Save</button>
                        <button class="cont13 col-md-3 ml-2" (click)="printTexteditorData()" [disabled]="(html != '' && html != null)?'':null">print3</button>
                    </div>
                </div> 
            </div>

            <div class="normalTextfield" *ngIf="!labSampleTextEditor">
                <div class="mode-box" >
                    <div class="row m-0" >
                        <div class="col-md-2 modname text-white mt-1 text-center">PARAMETER</div>
                        <div class="col-md-2 modname text-white mt-1 text-center">RESULT</div>
                        <div class="col-md-1 modname text-white mt-1 text-center">UNITS</div>
                        <div class="col-md-2 modname text-white mt-1 text-center">REFERENCE RANGES</div>
                        <div class="col-md-3 modname text-white mt-1 text-center">REMARKS</div>
                        <div class="col-md-2 modname text-white mt-1 text-center">METHODOLOGY</div>
                    </div>
                </div>
    
                <ng-container *ngIf="labServiceResult.length != 0 && labServiceType != 'subservices'">
                    <div class="row" *ngFor="let service of labServiceResult; let i= index">

                        <ng-container *ngIf="service['SUB_TESTS'].length != 0 && service['SUB_PACKAGE_TESTS']">
                            <div class="col-md-12 resultLabel" *ngIf="service['SUB_TESTS'].length != 0"><h5>1{{service['NAME']}}</h5></div>

                            <ng-container *ngIf="service['SUB_TESTS'].length != 0">
                                <div class="row pl-4" *ngFor="let subTest of service['SUB_TESTS'];let j=index;">
                                    <div class="col-md-10 pl-3 resultLabel"><h6>{{subTest['NAME']}}</h6></div><br />
                                    <ng-container  *ngIf="subTest['SUB_TEST_SERVICES'].length != 0">
                                    
                                        <div class="col-md-12 pl-3" *ngFor="let subTestService of subTest['SUB_TEST_SERVICES']">
                                            <div class="row pl-2">
                                                <div class="col-md-2">{{subTestService.TEST_PROFILE_NAME}}</div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subTestService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subTestService)" value="subTestService.RESULT_NUMERIC_VALUE" [disabled]="subTestService.RESULT_STATUS">

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="subTestService.RESULT_ALERT !='' " *ngIf="subTestService.RESULT_STATUS">{{subTestService.RESULT_ALERT}}</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MIN_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE && subTestService.MIN_VALUE > subTestService.RESULT_NUMERIC_VALUE )" *ngIf="!subTestService.RESULT_STATUS">L</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MAX_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE && subTestService.RESULT_NUMERIC_VALUE > subTestService.MAX_VALUE)" *ngIf="!subTestService.RESULT_STATUS">H</span>
                                                </div>
                                            
                                                <div class="col-md-1 text-center mt-1 padTop">
                                                    <input type="text" name="serviceUnits" [(ngModel)]="subTestService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subTestService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subTestService)" >
                                                </div>
                                                <div class="col-md-2 text-center mt-1">
                                                    <textarea name="serviceRefRange" [(ngModel)]="subTestService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)" >{{subTestService.RESULT_NORMAL_RANGES}}</textarea>
                                                </div>
                                                <div class="col-md-3 text-center mt-1">
                                                    <textarea name="serviceResultRemarks" [(ngModel)]="subTestService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)">{{subTestService.RESULT_REMARKS}}</textarea>
                                                </div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="methodology" [(ngModel)]="subTestService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subTestService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subTestService)">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="subTest['SUB_TEST_INNER_SERVICES'].length != 0">
                                        <div class="col-md-12 pl-4" *ngFor="let innerHeading of subTest['SUB_TEST_INNER_SERVICES'] | keyvalue">
                                            <div class="row col-md-12 pl-3 resultLabel"><h6>{{subTest.SUB_TEST_INNER_SERVICES[innerHeading.key][0].SUBTEST_INNER_HEADING}}</h6></div><br />
                                            <ng-container>
                                                <div class="col-md-12" *ngFor="let innerService of subTest['SUB_TEST_INNER_SERVICES'][innerHeading.key];let k = index;">
                                                    <div class="row">
                                                        <div class="col-md-2">{{innerService.TEST_PROFILE_NAME}}</div>
                                                        <div class="col-md-2 text-center mt-1 padTop">
                                                            <input type="text" name="serviceResult" class="mode-input1"  [(ngModel)]="innerService.RESULT_NUMERIC_VALUE"  #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(innerService)" value="innerService.RESULT_NUMERIC_VALUE" [disabled]="innerService.RESULT_STATUS">

                                                            <span class="resultEnterStatus" [class.checkresultStatus]="innerService.RESULT_ALERT !='' " *ngIf="innerService.RESULT_STATUS">{{innerService.RESULT_ALERT}}</span>

                                                            <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MIN_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE && innerService.MIN_VALUE > innerService.RESULT_NUMERIC_VALUE )" *ngIf="!innerService.RESULT_STATUS">L</span>

                                                            <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MAX_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE && innerService.RESULT_NUMERIC_VALUE > innerService.MAX_VALUE )" *ngIf="!innerService.RESULT_STATUS">H</span>
                                                        </div>
                                                    
                                                        <div class="col-md-1 text-center mt-1 padTop">
                                                            <input type="text" name="serviceUnits" [(ngModel)]="innerService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="innerService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(innerService)" >
                                                        </div>
                                                        <div class="col-md-2 text-center mt-1">
                                                            <textarea name="serviceRefRange" [(ngModel)]="innerService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)" >{{innerService.RESULT_NORMAL_RANGES}}</textarea>
                                                        </div>
                                                        <div class="col-md-3 text-center mt-1">
                                                            <textarea name="serviceResultRemarks" [(ngModel)]="innerService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)">{{innerService.RESULT_REMARKS}}</textarea>
                                                        </div>
                                                        <div class="col-md-2 text-center mt-1 padTop">
                                                            <input type="text" name="methodology" [(ngModel)]="innerService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="innerService.RESULT_METHOD" (ngModelChange)="checkResultServiceIsActive(innerService)">
                                                        </div>
                                                
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    
                                </div>
                            </ng-container>

                        </ng-container>
                        <!-- serive itself -->
                        <ng-container *ngIf="service['SUB_TESTS'].length == 0 && service['SUB_TEST_SERVICES'].length == 0  && service['SUB_TEST_INNER_SERVICES'].length == 0  && !service['SUB_PACKAGE_TESTS']">
                            <div class="row col-md-12 pl-5">
                                <div class="col-md-2 resultLabel">{{service.NAME}}</div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="service.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(service)" value="service.RESULT_NUMERIC_VALUE" [disabled]="service.RESULT_STATUS">

                                    <span class="resultEnterStatus" [class.checkresultStatus]="service.RESULT_ALERT !='' " *ngIf="service.RESULT_STATUS">{{service.RESULT_ALERT}}</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(service.MIN_VALUE != NULL && service.RESULT_NUMERIC_VALUE != '' && service.RESULT_NUMERIC_VALUE && service.MIN_VALUE > service.RESULT_NUMERIC_VALUE )" *ngIf="!service.RESULT_STATUS">L</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(service.MAX_VALUE != NULL && service.RESULT_NUMERIC_VALUE != '' && service.RESULT_NUMERIC_VALUE && service.RESULT_NUMERIC_VALUE > service.MAX_VALUE )" *ngIf="!service.RESULT_STATUS">H</span>
                                </div>
                            
                                <div class="col-md-1 text-center mt-1 padTop">
                                    <input type="text" name="serviceUnits" [(ngModel)]="service.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="service.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(service)" >
                                </div>
                                <div class="col-md-2 text-center mt-1">
                                    <textarea name="serviceRefRange" [(ngModel)]="service.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{service.RESULT_NORMAL_RANGES}}</textarea>
                                </div>
                                <div class="col-md-3 text-center mt-1">
                                    <textarea name="serviceResultRemarks" [(ngModel)]="service.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)">{{service.RESULT_REMARKS}}</textarea>
                                </div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="methodology" [(ngModel)]="service.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="service.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(service)">
                                </div>
                        
                            </div>
                                
                        </ng-container>
                        <!-- sub sub service -->
                        <ng-container *ngIf="service['SUB_TESTS'].length == 0 && !service['SUB_PACKAGE_TESTS']  && service['SUB_TEST_INNER_SERVICES'].length != 0">
                            <div class="col-md-12 resultLabel"><h5>{{service['NAME']}}</h5></div>
                            <ng-container *ngIf="service['SUB_TESTS'].length == 0  && service['SUB_TEST_SERVICES'].length != 0">
                                
                                <ng-container>
                                    <div class="col-md-12" *ngFor="let testService of service['SUB_TEST_SERVICES'];let p = index;">
                                        <div class="row">
                                            <div class="col-md-2">{{testService.TEST_PROFILE_NAME}}</div>
                                            <div class="col-md-2 text-center mt-1 padTop">
                                                <input type="text" name="serviceResult" class="mode-input1"  [(ngModel)]="testService.RESULT_NUMERIC_VALUE"  #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(testService)" value="testService.RESULT_NUMERIC_VALUE" [disabled]="testService.RESULT_STATUS">
                                                
                                                <span class="resultEnterStatus" [class.checkresultStatus]="testService.RESULT_ALERT !='' " *ngIf="testService.RESULT_STATUS">{{testService.RESULT_ALERT}}</span>

                                                <span class="resultEnterStatus" [class.checkresultStatus]="(testService.MIN_VALUE != NULL && testService.RESULT_NUMERIC_VALUE != '' && testService.RESULT_NUMERIC_VALUE && testService.MIN_VALUE > testService.RESULT_NUMERIC_VALUE )" *ngIf="!testService.RESULT_STATUS">L</span>

                                                <span class="resultEnterStatus" [class.checkresultStatus]="(testService.MAX_VALUE != NULL && testService.RESULT_NUMERIC_VALUE != '' && testService.RESULT_NUMERIC_VALUE && testService.RESULT_NUMERIC_VALUE > testService.MAX_VALUE )" *ngIf="!testService.RESULT_STATUS">H</span>
                                            </div>
                                        
                                            <div class="col-md-1 text-center mt-1 padTop">
                                                <input type="text" name="serviceUnits" [(ngModel)]="testService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="testService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(testService)" >
                                            </div>
                                            <div class="col-md-2 text-center mt-1">
                                                <textarea name="serviceRefRange" [(ngModel)]="testService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2" (ngModelChange)="checkResultServiceIsActive(testService)" >{{testService.RESULT_NORMAL_RANGES}}</textarea>
                                            </div>
                                            <div class="col-md-3 text-center mt-1">
                                                <textarea name="serviceResultRemarks" [(ngModel)]="testService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2" (ngModelChange)="checkResultServiceIsActive(testService)">{{testService.RESULT_REMARKS}}</textarea>
                                            </div>
                                            <div class="col-md-2 text-center mt-1 padTop">
                                                <input type="text" name="methodology" [(ngModel)]="testService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="testService.RESULT_METHOD" (ngModelChange)="checkResultServiceIsActive(testService)">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="service['SUB_TESTS'].length == 0  && service['SUB_TEST_INNER_SERVICES'].length != 0">
                                <div class="col-md-12 pl-4" *ngFor="let innerHeading of service['SUB_TEST_INNER_SERVICES'] | keyvalue">
                                    <div class="row col-md-12 pl-3 resultLabel"><h6>{{service.SUB_TEST_INNER_SERVICES[innerHeading.key][0].SUBTEST_INNER_HEADING}}</h6></div><br />
                                    <ng-container>
                                        <div class="col-md-12" *ngFor="let innerService of service['SUB_TEST_INNER_SERVICES'][innerHeading.key];let k = index;">
                                            <div class="row">
                                                <div class="col-md-2">{{innerService.TEST_PROFILE_NAME}}</div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="serviceResult" class="mode-input1"  [(ngModel)]="innerService.RESULT_NUMERIC_VALUE"  #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(innerService)" value="innerService.RESULT_NUMERIC_VALUE" [disabled]="innerService.RESULT_STATUS">
                                                    
                                                    <span class="resultEnterStatus" [class.checkresultStatus]="innerService.RESULT_ALERT !='' " *ngIf="innerService.RESULT_STATUS">{{innerService.RESULT_ALERT}}</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MIN_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE && innerService.MIN_VALUE > innerService.RESULT_NUMERIC_VALUE )" *ngIf="!innerService.RESULT_STATUS">L</span>

                                                    <span class="resultEnterStatus" [class.checkresultStatus]="(innerService.MAX_VALUE != NULL && innerService.RESULT_NUMERIC_VALUE != '' && innerService.RESULT_NUMERIC_VALUE > innerService.MAX_VALUE )" *ngIf="!innerService.RESULT_STATUS">H</span>
                                                </div>
                                            
                                                <div class="col-md-1 text-center mt-1 padTop">
                                                    <input type="text" name="serviceUnits" [(ngModel)]="innerService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="innerService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(innerService)" >
                                                </div>
                                                <div class="col-md-2 text-center mt-1">
                                                    <textarea name="serviceRefRange" [(ngModel)]="innerService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)" >{{innerService.RESULT_NORMAL_RANGES}}</textarea>
                                                </div>
                                                <div class="col-md-3 text-center mt-1">
                                                    <textarea name="serviceResultRemarks" [(ngModel)]="innerService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2" (ngModelChange)="checkResultServiceIsActive(innerService)">{{innerService.RESULT_REMARKS}}</textarea>
                                                </div>
                                                <div class="col-md-2 text-center mt-1 padTop">
                                                    <input type="text" name="methodology" [(ngModel)]="innerService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="innerService.RESULT_METHOD" (ngModelChange)="checkResultServiceIsActive(innerService)">
                                                </div>
                                        
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                            

                        </ng-container>
                        <ng-container  *ngIf="service['SUB_TESTS'].length == 0 && service['SUB_TEST_SERVICES'].length != 0 && service['SUB_TEST_INNER_SERVICES'].length == 0">
                            <div class="col-md-12 resultLabel"><h5>{{service['NAME']}}</h5></div>
                            <div class="col-md-12 pl-3" *ngFor="let subTestService of service['SUB_TEST_SERVICES']">
                                <div class="row pl-2">
                                    <div class="col-md-2">{{subTestService.TEST_PROFILE_NAME}}</div>
                                    <div class="col-md-2 text-center mt-1 padTop">
                                        <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subTestService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subTestService)" [disabled]="subTestService.RESULT_STATUS" >
                                        
                                        <span class="resultEnterStatus" [class.checkresultStatus]="subTestService.RESULT_ALERT !='' " *ngIf="subTestService.RESULT_STATUS">{{subTestService.RESULT_ALERT}}</span>

                                        <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MIN_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE  && subTestService.MIN_VALUE > subTestService.RESULT_NUMERIC_VALUE )" *ngIf="!subTestService.RESULT_STATUS">L</span>

                                        <span class="resultEnterStatus" [class.checkresultStatus]="(subTestService.MAX_VALUE != NULL && subTestService.RESULT_NUMERIC_VALUE != '' && subTestService.RESULT_NUMERIC_VALUE && subTestService.RESULT_NUMERIC_VALUE > subTestService.MAX_VALUE )" *ngIf="!subTestService.RESULT_STATUS">H</span>
                                    </div>
                                
                                    <div class="col-md-1 text-center mt-1 padTop">
                                        <input type="text" name="serviceUnits" [(ngModel)]="subTestService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subTestService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subTestService)" >
                                    </div>
                                    <div class="col-md-2 text-center mt-1">
                                        <textarea name="serviceRefRange" [(ngModel)]="subTestService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)" >{{subTestService.RESULT_NORMAL_RANGES}}</textarea>
                                    </div>
                                    <div class="col-md-3 text-center mt-1">
                                        <textarea name="serviceResultRemarks" [(ngModel)]="subTestService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subTestService)">{{subTestService.RESULT_REMARKS}}</textarea>
                                    </div>
                                    <div class="col-md-2 text-center mt-1 padTop">
                                        <input type="text" name="methodology" [(ngModel)]="subTestService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subTestService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subTestService)">
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </ng-container>

                <!-- Renal Profile Sub Tests -->

                <ng-container *ngIf="labServiceResult.length != 0 && labServiceType == 'subservices'">
                    <!-- Individual subTest Start -->
                    <div class="col-md-12 resultLabel" *ngIf="labServiceResult.single.length != 0"><h5>{{labServiceResult.single[0].SERVICE_NAME}}</h5></div>
                    <div class="row" *ngFor="let sigleService of labServiceResult.single; let i= index">
                        <div class="row col-md-12 pl-5">
                            <div class="col-md-2 resultLabel1">{{sigleService.TEST_PROFILE_NAME}}</div>
                            <div class="col-md-2 text-center mt-1 padTop">
                                <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="sigleService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(sigleService)" value="sigleService.RESULT_NUMERIC_VALUE" [disabled]="sigleService.RESULT_STATUS">

                                <span class="resultEnterStatus" [class.checkresultStatus]="sigleService.RESULT_ALERT !='' " *ngIf="sigleService.RESULT_STATUS">{{sigleService.RESULT_ALERT}}</span>

                                <span class="resultEnterStatus" [class.checkresultStatus]="(sigleService.MIN_VALUE != NULL && sigleService.RESULT_NUMERIC_VALUE != '' && sigleService.RESULT_NUMERIC_VALUE && sigleService.MIN_VALUE > sigleService.RESULT_NUMERIC_VALUE )" *ngIf="!sigleService.RESULT_STATUS">L</span>

                                <span class="resultEnterStatus" [class.checkresultStatus]="(sigleService.MAX_VALUE != NULL && sigleService.RESULT_NUMERIC_VALUE != '' && sigleService.RESULT_NUMERIC_VALUE > sigleService.MAX_VALUE )" *ngIf="!sigleService.RESULT_STATUS">H</span>
                            </div>
                        
                            <div class="col-md-1 text-center mt-1 padTop">
                                <input type="text" name="serviceUnits" [(ngModel)]="sigleService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="sigleService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(sigleService)" >
                            </div>
                            <div class="col-md-2 text-center mt-1">
                                <textarea name="serviceRefRange" [(ngModel)]="sigleService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{sigleService.RESULT_NORMAL_RANGES}}</textarea>
                            </div>
                            <div class="col-md-3 text-center mt-1">
                                <textarea name="serviceResultRemarks" [(ngModel)]="sigleService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(sigleService)">{{sigleService.RESULT_REMARKS}}</textarea>
                            </div>
                            <div class="col-md-2 text-center mt-1 padTop">
                                <input type="text" name="methodology" [(ngModel)]="sigleService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="sigleService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(sigleService)">
                            </div>
                    
                        </div>
                    </div>
                    <!-- Individual subTest End -->
                    <div class="col-md-12 p-0 m-0" *ngFor="let sigleSubService of labServiceResult.subService | keyvalue; let l= index">
                        <div class="col-md-12 resultLabel pl-4" *ngIf="sigleSubService.key != ''"><h5>{{sigleSubService.key}}</h5></div>
                        <div class="row" *ngFor="let subService of labServiceResult.subService[sigleSubService.key]['single']; let k= index">
                            <div class="row col-md-12 pl-5">
                                <div class="col-md-2 resultLabel1 pl-4">{{subService.TEST_PROFILE_NAME}}</div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subService)" value="subService.RESULT_NUMERIC_VALUE" [disabled]="subService.RESULT_STATUS">
                                    
                                    <span class="resultEnterStatus" [class.checkresultStatus]="subService.RESULT_ALERT !='' " *ngIf="subService.RESULT_STATUS">{{subService.RESULT_ALERT}}</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subService.MIN_VALUE != NULL && subService.RESULT_NUMERIC_VALUE != '' && subService.RESULT_NUMERIC_VALUE && subService.MIN_VALUE > subService.RESULT_NUMERIC_VALUE )" *ngIf="!subService.RESULT_STATUS">L</span>

                                    <span class="resultEnterStatus" [class.checkresultStatus]="(subService.MAX_VALUE != NULL && subService.RESULT_NUMERIC_VALUE != '' && subService.RESULT_NUMERIC_VALUE > subService.MAX_VALUE )" *ngIf="!subService.RESULT_STATUS">H</span>
                                </div>
                            
                                <div class="col-md-1 text-center mt-1 padTop">
                                    <input type="text" name="serviceUnits" [(ngModel)]="subService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subService)" >
                                </div>
                                <div class="col-md-2 text-center mt-1">
                                    <textarea name="serviceRefRange" [(ngModel)]="subService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{subService.RESULT_NORMAL_RANGES}}</textarea>
                                </div>
                                <div class="col-md-3 text-center mt-1">
                                    <textarea name="serviceResultRemarks" [(ngModel)]="subService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subService)">{{subService.RESULT_REMARKS}}</textarea>
                                </div>
                                <div class="col-md-2 text-center mt-1 padTop">
                                    <input type="text" name="methodology" [(ngModel)]="subService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subService)">
                                </div>
                            
                            </div>
                        </div>
                        <div *ngIf="labServiceResult.subService[sigleSubService.key]['innerHead'] != undefined">
                            <div class="col-md-12" *ngFor="let subInnerService of labServiceResult.subService[sigleSubService.key]['innerHead'] | keyvalue; let t= index">
                                <div class="col-md-12 resultLabel pl-4">{{subInnerService.key}}</div>
                                <div class="col-md-12" *ngFor="let subInnerHeadService of labServiceResult.subService[sigleSubService.key]['innerHead'][subInnerService.key]; let t= index">
                                    <div class="row col-md-12 pl-3">
                                        <div class="col-md-2 resultLabel1 pl-3">{{subInnerHeadService.TEST_PROFILE_NAME}}</div>
                                        <div class="col-md-2 text-center mt-1 padTop">
                                            <input type="text" name="serviceResult" class="mode-input1" [(ngModel)]="subInnerHeadService.RESULT_NUMERIC_VALUE" #serviceResult = "ngModel" (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)" value="subInnerHeadService.RESULT_NUMERIC_VALUE" [disabled]="subInnerHeadService.RESULT_STATUS">
                                            
                                            <span class="resultEnterStatus" [class.checkresultStatus]="subInnerHeadService.RESULT_ALERT !='' " *ngIf="subInnerHeadService.RESULT_STATUS">{{subInnerHeadService.RESULT_ALERT}}</span>

                                            <span class="resultEnterStatus" [class.checkresultStatus]="(subInnerHeadService.MIN_VALUE != NULL && subInnerHeadService.RESULT_NUMERIC_VALUE != '' && subInnerHeadService.RESULT_NUMERIC_VALUE  && subInnerHeadService.MIN_VALUE > subInnerHeadService.RESULT_NUMERIC_VALUE )" *ngIf="!subInnerHeadService.RESULT_STATUS">L</span>

                                            <span class="resultEnterStatus" [class.checkresultStatus]="(subInnerHeadService.MAX_VALUE != NULL && subInnerHeadService.RESULT_NUMERIC_VALUE != '' && subInnerHeadService.RESULT_NUMERIC_VALUE && subInnerHeadService.RESULT_NUMERIC_VALUE > subInnerHeadService.MAX_VALUE )" *ngIf="!subInnerHeadService.RESULT_STATUS">H</span>
                                        </div>
                                    
                                        <div class="col-md-1 text-center mt-1 padTop">
                                            <input type="text" name="serviceUnits" [(ngModel)]="subInnerHeadService.RESULT_UNITS" #serviceUnits = "ngModel" class="mode-units" value="subInnerHeadService.RESULT_UNITS" (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)" >
                                        </div>
                                        <div class="col-md-2 text-center mt-1">
                                            <textarea name="serviceRefRange" [(ngModel)]="subInnerHeadService.RESULT_NORMAL_RANGES" #serviceRefRange = "ngModel" class="mode-textarea refRange" rows="2"  (ngModelChange)="checkResultServiceIsActive(service)" >{{subInnerHeadService.RESULT_NORMAL_RANGES}}</textarea>
                                        </div>
                                        <div class="col-md-3 text-center mt-1">
                                            <textarea name="serviceResultRemarks" [(ngModel)]="subInnerHeadService.RESULT_REMARKS" #serviceResultRemarks = "ngModel" class="mode-textarea" rows="2"  (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)">{{subInnerHeadService.RESULT_REMARKS}}</textarea>
                                        </div>
                                        <div class="col-md-2 text-center mt-1 padTop">
                                            <input type="text" name="methodology" [(ngModel)]="subInnerHeadService.RESULT_METHOD" #methodology = "ngModel" class="mode-input1" value="subInnerHeadService.RESULT_METHOD"  (ngModelChange)="checkResultServiceIsActive(subInnerHeadService)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>

                <div class="row m-4">
                    <div class="col-md-8">
                        <div class="form-group row no-gutters">
                            <label class="col-4 modname reportHeading pt-1 pl-2"><h6><b>Reporting Doctors</b></h6></label>
                            <div class="col-5 px-0">
                                <select 
                                class="form-control input-textt2 w-100"
                                name="reportingDoctor" 
                                id="reportingDoctor"
                                [(ngModel)]="selectedreportingDoctorID"
                                #reportingDoctor = "ngModel" 
                                required>
                                <option value="0">Select Doctor Name</option>
                                <option *ngFor="let doctor of reportingDoctors" 
                                [ngValue]="doctor?.reportingDoctorId">{{doctor?.reportingDoctor}}</option>
                                </select> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-right">
                        <button class="cont13 col-md-3 ml-2" (click)="addLabResultsData(labServiceResult)" [disabled]="labResPrintBtn && labReportingDoctors[selectedreportingDoctorID] == selectedDoctor">Save</button>
                        <button class="cont13 col-md-3 ml-2" [disabled]="!labResPrintBtn || (labReportingDoctors[selectedreportingDoctorID] != selectedDoctor)" (click)="printLabResultsFinal()">print</button>
                    </div>
                </div> 
            </div>
            
        </div>    
    </div>
</ng-template>
</div>

<!-- Ip Billing Info controls -->
<ng-template #ipBillingPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Input Controls</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="ipBillingForm" (ngSubmit)="submitIpForm(IpServicePopup)">
        <div style="font-size: 16px;">
          <div class="row mt-3 mb-3">
            <label for="doctorname" class="col-md-5 font-weight-bold font">Service Name</label>
            <div class="col-md-7">
                <select 
                class="form-control input-textt2 w-100"
                name="searchServiceName" 
                formControlName="SERVICENAME"
                id="searchServiceName" 
                >
                <option value="">Select Service Name</option>
                <option *ngFor="let service of labServices" 
                [ngValue]="service?.sId">{{service?.serviceName}}</option>
                </select>
            </div>                                
          </div>
  
          <div class="row mt-3 mb-3">
              <label for="start" class="col-md-5 font-weight-bold font">From Date</label>
              <div class="col-md-7">
                <input type="date" class="form-control-sm form-control border border-primary" 
                name="start" placeholder="DD-MM-YYYY"
                formControlName="FROMDATE"
                  id="start" >
              </div>
          </div>
          <div class="row mt-3 mb-3">
            <label for="end" class="col-md-5 font-weight-bold font">To Date</label>
            <div class="col-md-7">
              <input type="date" class="form-control-sm form-control border border-primary" 
              name="end" placeholder="DD-MM-YYYY"
              formControlName="TODATE"
                id="end" >
            </div>
          </div>
        </div>
  
        <div class="text-right mt-5 mb-1 mr-5">
          <button type="submit" class="col-md-2 btn btn-sm mx-2 p-2 text-white"
            style="background-color: #173267; width: 80px">Search</button>
          <button (click)="resetFields()" type="button"
            class="col-md-2 btn btn-sm mx-2 p-2 resetSub text-white"
            style="background-color: #173267; width: 80px">Reset</button>        
        </div>      
      </form>
    </div>
</ng-template>

<!-- Op Billing Info controls -->
<ng-template #opBillingPopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Input Controls</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="opBillingForm" (ngSubmit)="submitOpForm(OpServicePopup)">
        <div style="font-size: 16px;">
          <div class="row mt-3 mb-3">
            <label for="doctorname" class="col-md-5 font-weight-bold font">Service Name</label>
            <div class="col-md-7">
                <select 
                class="form-control input-textt2 w-100"
                name="searchServiceName" 
                formControlName="SERVICENAME"
                id="searchServiceName" 
                >
                <option value="">Select Service Name</option>
                <option *ngFor="let service of labServices" 
                [ngValue]="service?.sId">{{service?.serviceName}}</option>
                </select>
            </div>                                
          </div>
  
          <div class="row mt-3 mb-3">
              <label for="start" class="col-md-5 font-weight-bold font">From Date</label>
              <div class="col-md-7">
                <input type="date" class="form-control-sm form-control border border-primary" 
                name="start" placeholder="DD-MM-YYYY"
                formControlName="FROMDATE"
                  id="start" >
              </div>
          </div>
          <div class="row mt-3 mb-3">
            <label for="end" class="col-md-5 font-weight-bold font">To Date</label>
            <div class="col-md-7">
              <input type="date" class="form-control-sm form-control border border-primary" 
              name="end" placeholder="DD-MM-YYYY"
              formControlName="TODATE"
                id="end" >
            </div>
          </div>
        </div>
  
        <div class="text-right mt-5 mb-1 mr-5">
          <button type="submit" class="col-md-2 btn btn-sm mx-2 p-2 text-white"
            style="background-color: #173267; width: 80px">Search</button>
          <button (click)="resetFields()" type="button"
            class="col-md-2 btn btn-sm mx-2 p-2 resetSub text-white"
            style="background-color: #173267; width: 80px">Reset</button>        
        </div>      
      </form>
    </div>
</ng-template>

<!-- IP Billing Sevice patients List -->
<ng-template #IpServicePopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">IP Billing Service Report</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="text-right">
            <!-- <button class="btn mx-3 mb-2 p-2 text-white" style="background-color: #173267;" (click)="printCollectionsList()" type="button" value="Print">Print</button> -->
            <button class="btn btn-sm mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="ipDataExport()" type="button">Export to Excel</button>
            <button class="btn btn-sm mx-3 mb-2 p-2 text-white" style="background-color: #173267;" (click)="popupClose()" type="submit" value="Close">Close</button>
        </div>
        <table class="table table-bordered table-responsive table-striped" id="ip-data">
            <thead>
            <tr>
                <th class=" fweight600 wid120">S.No </th>
                <th class=" fweight600 wid120">IP NO</th>
                <th class=" fweight600 wid120">UMR NO</th>
                <th class=" fweight600 wid120">Patient Name</th>
                <th class=" fweight600 wid120">Gender</th>
                <th class=" fweight600 wid120">Service Name</th>
                <th class=" fweight600 wid120">Tariff Name</th>
                <th class=" fweight600 wid120">Payment Date</th>
                <th class=" fweight600 wid120">Gross Amount</th>
                <th class=" fweight600 wid120">Discount Amount</th>
                <th class=" fweight600 wid120">Net Amount</th>
                <th class=" fweight600 wid120">Created By</th>
                <th class=" fweight600 wid120">Created On</th>
                <th class=" fweight600 wid120">Patient Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let patient of ipBillingInfo; let i = index" class="handCursor">
                <td>{{i+1}}</td>
                <td>{{patient.IpNo}}</td>
                <td>{{patient.patientId}}</td>
                <td>{{patient.patientName}}</td>
                <td>{{patient.gender}}</td>
                <td>{{patient.serviceName}}</td>
                <td>{{patient.tarieffName}}</td>
                <td>{{patient.billedDate}}</td>
                <td>{{patient.grossAmount}}</td>
                <td>{{patient.discountAmount}}</td>
                <td>{{patient.netAmount}}</td>
                <td>{{patient.createdBy}}</td>
                <td>{{patient.createdOn}}</td>
                <td>{{patient.patientStatus}}</td>
            </tr>
            </tbody>
        </table>     
    </div>  
</ng-template>

<!-- OP Billing Sevice patients List -->
<ng-template #OpServicePopup let-modal>
    <div class="modal-header">
      <h4 class="modal-title">OP Billing Service Report</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="text-right">
            <!-- <button class="btn mx-3 mb-2 p-2 text-white" style="background-color: #173267;" (click)="printCollectionsList()" type="button" value="Print">Print</button> -->
            <button class="btn btn-sm mx-2 mb-2 p-2 text-white" style="background-color: #173267;" (click)="opDataExport()" type="button">Export to Excel</button>
            <button class="btn btn-sm mx-3 mb-2 p-2 text-white" style="background-color: #173267;" (click)="popupClose()" type="submit" value="Close">Close</button>
        </div>
        <table class="table table-bordered table-responsive table-striped" id="op-data">
            <thead>
            <tr>
                <th class=" fweight600 wid120">S.No </th>
                <th class=" fweight600 wid120">VisitID</th>
                <th class=" fweight600 wid120">UMR NO</th>
                <th class=" fweight600 wid120">Patient Name</th>
                <th class=" fweight600 wid120">Gender</th>
                <th class=" fweight600 wid120">Service Name</th>
                <th class=" fweight600 wid120">Tariff Name</th>
                <th class=" fweight600 wid120">Payment Date</th>
                <th class=" fweight600 wid120">Gross Amount</th>
                <th class=" fweight600 wid120">Discount Amount</th>
                <th class=" fweight600 wid120">Net Amount</th>
                <th class=" fweight600 wid120">Created By</th>
                <th class=" fweight600 wid120">Created On</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let patient of opBillingInfo; let i = index" class="handCursor">
                <td>{{i+1}}</td>
                <td>{{patient.VisitId}}</td>
                <td>{{patient.patientId}}</td>
                <td>{{patient.patientName}}</td>
                <td>{{patient.gender}}</td>
                <td>{{patient.serviceName}}</td>
                <td>{{patient.tarieffName}}</td>
                <td>{{patient.billedDate}}</td>
                <td>{{patient.grossAmount}}</td>
                <td>{{patient.discountAmount}}</td>
                <td>{{patient.netAmount}}</td>
                <td>{{patient.createdBy}}</td>
                <td>{{patient.createdOn}}</td>
            </tr>
            </tbody>
        </table>       
    </div>  
</ng-template>

<!-- Final Export Test -->
<ng-container  *ngFor="let services of finalHTMLPages; let i= index; last as isLast;  first as isFirst">
    <ng-container *ngFor="let finalService of services.services; let k= index; last as pdfLast; first as pdfFirst">
            
        <div *ngIf="finalService.length !== 0" style="overflow: hidden; height: 0;" class="print-form">
            <table style="border: 0px!important;" width="100%" id="labprint-section-custom-single">
                <thead id="headerCheck">  
                    <tr>  
                    <th>
                        <!-- OP demographic details -->
                        <div class="container">
                        <div class="row form-data mb-1 "
                            style="margin-top:180px;border-bottom: 1px solid black!important;" 
                            *ngIf="bodyDisplay == 'OP'">
                        <div class="col-md-5">
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Patient Name: {{patientInfo.title}} {{patientInfo.patientName}}</b></p> 
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Gender / Age:  {{patientInfo.gender}} / {{patientInfo.age}}</b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>UMR No: {{patientInfo.patientID}}</b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Referred By: Dr. {{patientInfo.referredBy}} </b></p>
                            <p style="font-size: 21px; margin-bottom: -10px;"><b>Print Date: {{getDate()}}</b></p> 
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-5">
                                <p *ngIf="bodyDisplay == 'IP'" style="font-size: 20px; margin-bottom: -15px;"><b>IP NR: {{patientInfo.IP_NR}}</b></p> 
                                <p *ngIf="bodyDisplay == 'OP'" style="font-size: 20px; margin-bottom: -15px;"><b>VisitID: {{patientInfo.visitID}}</b></p> 
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Sample ID: <span style="font-size: 21px;" *ngIf = "labServicePrint.length !=0" ><b style="font-size: 21px;">&nbsp;{{labSampleIds[this.resultIndex]}}</b></span></b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Sample Date : <span style="font-size: 21px;" *ngIf = "labServicePrint.length !=0" ><b style="font-size: 21px;">&nbsp;{{labServicePrint.serviceRequestDate}} {{labServicePrint.serviceRequestTime}}</b></span> </b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Reported Date: <span style="font-size: 21px;" *ngIf = "labServicePrint.length !=0" ><b style="font-size: 21px;">&nbsp;{{labServicePrint.reportedDateTime}}</b></span></b></p>
                        </div>
                        </div>
                    </div>
                        <!-- IP demographic details -->
                        <div class="container">
                        <div class="row form-data mb-1" 
                            style="margin-top:90px;border-bottom: 1px solid black!important;" 
                            *ngIf="bodyDisplay == 'IP'">
                        <div class="col-md-5">
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Patient Name: {{patientInfo.TITLE}} {{patientInfo.PATIENT_NAME}}</b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Gender / Age: {{patientInfo.GENDER}} / {{patientInfo.AGE}}</b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>UMR No:  {{patientInfo.patientID}}</b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Referred By: Dr. {{patientInfo.REFERRED_BY}}</b></p> 
                            <p style="font-size: 21px; margin-bottom: -10px;"><b>Print Date: {{getDate()}} </b></p>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-5">
                            <ng-template *ngIf="bodyDisplay == 'IP'">
                                <p style="font-size: 21px; margin-bottom: -15px;"><b> IP NR: {{patientInfo.IP_NR}}</b></p>
                            </ng-template>
                            <ng-template *ngIf="bodyDisplay == 'OP'">
                                <p style="font-size: 21px; margin-bottom: -15px;"><b> VisitID:{{patientInfo.visitID}}</b></p>
                            </ng-template>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Sample ID: <span style="font-size: 21px;"><b>&nbsp;{{labSampleIds[this.resultIndex]}}</b></span></b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Sample Date: <span *ngIf = "labServicePrint.length !=0" 
                                style="font-size: 21px;"><b>&nbsp;{{labServicePrint.serviceRequestDate}} {{labServicePrint.serviceRequestTime}}</b></span></b></p>
                            <p style="font-size: 21px; margin-bottom: -15px;"><b>Reported Date:  <span *ngIf = "labServicePrint.length !=0" 
                                style="font-size: 21px;"><b>&nbsp;{{labServicePrint.reportedDateTime}}</b></span></b></p>
                        </div>
                        </div>
                    </div>
                    </th>  
                    </tr>  
                    <tr>  
                    <th><hr style="color:#000080"/></th>  
                    </tr>  
                </thead> 

                <!-- footer -->
                <tfoot> 
                    <tr>  
                    <td width="100%">  
                        <table width="100%" style="border: 0px!important;">  
                        <tr>  
                            <td colspan="4"><br />&nbsp;</td>  
                        </tr>  
                        </table>
                    </td>
                    </tr>  
                </tfoot>

                <!-- body -->
                
                <tbody>
                    <tr>  
                        <td width="100%">
                        <div class="container department text-center" 
                            *ngIf="labServicePrint.length != 0">
                            <p style="font-weight: bold; font-size: 24px; margin-top: -15px; margin-bottom: -10px;">DEPARTMENT OF {{labServicePrint.serviceDepartment}}</p>
                        </div>

                        <div class="container" *ngIf="isFirst && pdfFirst">
                            <div class="row" style="margin-top: 5px; margin-bottom:-10px;">
                                <div class="col-md-6"> <p style="font-size: 21px; font-weight: bold; margin-left: -10px;">Test Name</p> </div>
                                <div class="col-md-2"> <p style="font-size: 21px; font-weight: bold;margin-left: -30px;">Result</p> </div>
                                <div class="col-md-4"><p style="font-size: 21px; font-weight: bold;margin-left: -20px;">Reference Range</p> </div>
                            </div>
                        </div>

                        <!-- main tests -->
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="resultLabel" 
                                        style="font-size: 20px; font-weight: bold;text-decoration: underline;">
                                    {{services.testName}}
                                    </span>
                                </div>
                            </div>

                            <!-- sub testes -->
                            <ng-container *ngFor="let fservc of finalService; let i = index; first as isSubFirst">
                                <span style="font-size: 20px; font-weight: bold; color: #000;padding-left:10px;" *ngIf="fservc.subHeading !== 'No' && fservc.subHeading.includes('101010')">{{fservc.subHeading.replace('101010', '')}}</span>
                                <div class=" row col-md-12" [ngStyle]="{'margin-bottom': (fservc.referanceRange!=='') ? '-15px' : '-5px'}">
                                    <div class="col-md-6" style="margin-bottom: 10px;"> 
                                        <p style="font-size: 20px; font-weight: bold;">{{fservc.serviceName}}&nbsp;<span style="font-size: 18px;" 
                                            *ngIf="fservc.method">&nbsp;({{fservc.method}})
                                            </span></p> 
                                            
                                    </div>
                                    <div class="col-md-2" style="margin-top: 5px;"><!-- font-17 -->
                                    <span  *ngIf="fservc.abnormal === 'YES'" class="abnormal" style="font-size: 20px; ">{{fservc.result}}</span>
                                    <span  *ngIf="fservc.abnormal === 'NO'" style="font-size: 20px; ">{{fservc.result}}</span>

                                    </div>
                                    <div class="col-md-4"> 
                                        <p style="font-size: 20px;margin-top: 0px;">{{fservc.referanceRange}}</p> 
                                    </div>
                                </div>
                                
                            </ng-container>

                            <!-- last page subject -->
                            <ng-container *ngIf="pdfLast">
                                <div class="text-center">
                                    <p style="font-size: 18px!important;font-weight:bold;">----------: End of the report :---------- </p>
                                </div>  
                                <div class="text-right" style="margin-top:-10px;margin-right: 10px ; line-height: 2px; font-weight: bold;">
                                <div style="margin-top:-25px;">
                                    <div *ngIf="doctorSignature">
                                    <img style="width:120px"
                                        src="assets/digitalSignatures/reportingDoctorNames/{{doctorSignature}}" />
                                    </div>
                                    <p style="font-size: 18px">{{selectedReportDoctor}}</p><br />
                                    <p style="font-size: 18px; margin-top:10px;">{{selectedreportingDoctorDesignation}}</p>
                                </div>            
                                </div>
                            </ng-container>
                        

                        </div>        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </ng-container>

</ng-container>

